import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Layout } from '../common'

const NotFoundPage = () => (
	<Layout>
		<h1>NOT FOUND</h1>
		<h2><FormattedMessage id="message.test" /></h2>
		<p>404 Page Not Found</p>
	</Layout>
)

export default NotFoundPage
